import React from 'react';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { LinearProgress, Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import './FileList.css'
const theme = createTheme();

interface File{
    name:string,
    size:number
}

interface Props{
    file:File,
    isUploading?: boolean,
    isUploaded?: boolean,
    uploadProgress?: number,
    onDelete: (file: File) => void;
}



export const FileListItem : React.FC<Props> = ({file,onDelete,...stateProps}) => {
  return( 
    <Grid  item xs={12}  sx={{display:'flex',flexWrap:'wrap',p:0.5,border: 1,borderColor: 'grey.500',borderRadius: 3 }} justifyContent="flex-start" >
      <Grid item xs={1} className="extention" sx={{bgcolor:'lightblue',borderRadius: 2 }} >{file.name.split('.').pop()!.toUpperCase()}</Grid>
      <Grid item xs={11} sx={{display:'flex',flexWrap:'wrap',alignItems:'center'}}>
        <Grid item xs={8} lg={9} md={8} sx={{textAlign:'left',pl:1}}>
          <ListItemText className="fileListItem" >{file.name}</ListItemText>
        </Grid>
        <Grid item xs={2} sx={{color:'gray'}} className="fileListItem" >
          <Typography className='TypoGraphy' >
            {(() => {
              if(file.size>=1073741824)
                return (file.size / 1073741824).toFixed(2) + " GB"
              if(file.size>=1048576)
                return (file.size/1048576).toFixed(2) + " MB"
              if(file.size>=1024)
                return (file.size/1024).toFixed(2) + " KB"
              if(file.size>1)
                return file.size +" Bytes"
            })()}
          </Typography>
        </Grid>
        <Grid item xs={1} sx={{textAlign:'right'}} >
          { stateProps.isUploaded &&  <CheckIcon fontSize="small" color="success"/>}
          { !stateProps.isUploading && !stateProps.isUploaded && <ClearIcon onClick={() => onDelete(file)} className='deleteButton' />}
        </Grid>
        {stateProps.isUploading && <Grid item xs={12} sx={{mt:1,mb:2,ml:1,borderRadius:2}} >
          <LinearProgress variant="determinate" value={stateProps.uploadProgress}/>
        </Grid>}
      </Grid>
    </Grid>
  )
};