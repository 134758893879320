import * as React from 'react';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
interface Props{
    error:string;
}
export const ErrorListItem:React.FC<Props> = ({error}) => {
    return(

    <ListItem> <ListItemText>
    {error}
    </ListItemText></ListItem>  
    )
};