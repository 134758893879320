
import { PROJECT_DETAILS_LOADING, PROJECT_DETAILS_LOADED, PROJECT_DETAILS_LOAD_FAIL } from "../types/projectDetailTypes";
import { Project3dviewer } from "../../API"

export interface IProjectDetail {
    projectInfo: Project3dviewer | null
    streamingUrl:string,
    isLoading: boolean
    isLoaded: boolean
    error: string
    userRole:string | null
}

const InitialProjectDetailsState: IProjectDetail = {
    projectInfo: null,
    isLoading: false,
    streamingUrl:"",
    isLoaded: false,
    userRole:null,
    error: ""
}

const ProjectDetail = (state: IProjectDetail = InitialProjectDetailsState, action: any) => {
    switch(action.type) {
        case PROJECT_DETAILS_LOADING:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case PROJECT_DETAILS_LOADED:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                projectInfo: action.payload,
                streamingUrl:action.streamingUrl
            }
        case PROJECT_DETAILS_LOAD_FAIL:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.payload
            }

        default:
            return state;
    }
}

export default ProjectDetail;