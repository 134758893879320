
import { CHANGE_TRANSLATE_KEYS,JOBS_LOADED,JOBS_LOADING,JOBS_LOAD_FAIL,TRANSLATING_FILE_STARTED,EMPTY_KEYS } from "store/types/translateTypes";


import { Project3dviewer } from "../../API"

export interface IProjectDetail {
    keyArray:string[]
    jobsLoading:boolean
    jobsLoaded:boolean
    translateError:string
    jobsData:any[]
}

const InitialProjectDetailsState: IProjectDetail = {
    keyArray:[],
    jobsLoading:false,
    jobsLoaded:false,
    translateError:"",
    jobsData:[]
}

const translateReducer = (state: IProjectDetail = InitialProjectDetailsState, action: any) => {
    switch(action.type) {
        case CHANGE_TRANSLATE_KEYS:
            const newArray=[...state.keyArray]
            newArray.push(action.payload)
            return {
                ...state,
                keyArray:newArray
            };
        case TRANSLATING_FILE_STARTED:
            return {
                ...state,
                
            }
        case JOBS_LOADING:
            return{
                ...state,
                jobsLoading:true,
                jobsLoaded:false
            };
        case JOBS_LOADED:
            return{
                ...state,
                jobsLoading:false,
                jobsLoaded:true,
                jobsData:action.payload
            };
        case JOBS_LOAD_FAIL:
            return{
                ...state,
                jobsLoading:false,
                jobsLoaded:false,
                error:action.payload
            };
        case EMPTY_KEYS:
            return{
                ...state,
                keyArray:[]
            }
        default:
            return state;
    }
}

export default translateReducer;