export const getProject3dviewerByNameRelative = /* GraphQL */ `
  query GetProject3dviewerByNameRelative(
    $ProjectName: String
    $sortDirection: ModelSortDirection
    $filter: ModelProject3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProject3dviewerByName(
      ProjectName: $ProjectName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProjectName
        Origin_X
        Origin_Y
        createdAt
        updatedAt
        Application
        StreamingURL
        DataSets {
          items {
            dataSet {
            id
            DataSet
            Type {
              id
              Type      	
            }
            Priority
            FmeServerFilePath
            SharedData
            MinTileSize
            FmeServerCached
            Resolution
            }
          }
          nextToken
        }
        CoordinateSystem {
          id
          CoordinateSystem
          XMAX
          YMAX
          XMIN
          YMIN
          EPSG
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
