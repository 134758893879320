import {CREATE_BUCKET_VIEW,DELETING_FILE,CREATING_BUCKET_VIEW,FILE_DELETITION_FAIL,FILE_DELETED,DELETE_FOLDER,DELETE_FAIL,BUCKET_VIEW_FAIL} from '../types/BucketViewTypes'
import { useState,useEffect,useLayoutEffect } from 'react';
import {Auth} from "aws-amplify"
var axios = require('axios');

interface dataStructure{
    name:string,
    key:string,
    children:dataStructure[],
  }
  

  
   function transformdata(branch:string[],y:number,transformed:dataStructure,prevKey:String){
    
    if(y===branch.length)
          return
    if(transformed.name===branch[y])
    {
      let index=0
      transformed.children.map((child:dataStructure)=>{
        if(y+1<branch.length && child.name===branch[y+1])
          {
            transformdata(branch,y+1,child,transformed.key)
            return
          }
          index++
      })
      if(index==transformed.children.length && y+1<branch.length)
      {
        y=y+1
        let temp:dataStructure={name:"",key:"",children:[]}
        temp.name=branch[y]
        temp.key=transformed.key+"/"+branch[y]
        transformdata(branch,y+1,temp,temp.key)
        transformed.children=[...transformed.children,temp]  
      }
      
    }
    else{ 
      let temp:dataStructure={name:"",key:"",children:[]}
      temp.name=branch[y]
      temp.key=prevKey===""?branch[y]:prevKey+"/"+branch[y]
      transformdata(branch,y+1,temp,temp.key)
      if(transformed.name==="")
      {
        transformed.name=temp.name
        transformed.key=branch[y]
        transformed.children=temp.children
      }
      else
      transformed.children=[...transformed.children,temp]
    }
      return
    
  }

export const s3BucketView = (key:string,jwt:string) => async(dispatch: Function) => {
  
    try{
        dispatch({type:CREATING_BUCKET_VIEW})
        var config = {
        method: 'get',
        url: `https://jpadadle86.execute-api.eu-north-1.amazonaws.com/Prod/api/S3BucketTools/GetFilesFromFolder/3dviewer-bucket-dev/${key}`,
        headers: {
        'Authorization': `Bearer ${jwt}`
        }
            };
        
       await axios(config)
        .then(function (response:any) {
          
          let transformed:dataStructure={name:"",key:"",children:[]}
          response.data.map((item:any)=>{
            if(item.type.length>0)
              transformdata(item.key.split('/'),0,transformed!,"")
          })
          
          dispatch({type: CREATE_BUCKET_VIEW, payload: transformed})     
        })
        .catch(function (error:string) {
          dispatch({type: BUCKET_VIEW_FAIL, payload: error})     
            
        });
    }
    catch(error){
        dispatch({type: BUCKET_VIEW_FAIL, payload: error})
    }
}




export const deleteFile = (key:String,jwt:string) => async(dispatch: Function) => {
    dispatch({type:DELETING_FILE,payload:key})
    var data =JSON.stringify({
      "bucketName":"3dviewer-bucket-dev",
      "fileKeys":key,
      "deleteTransformData":false
    })
    //console.log(data)

    var config = {
    method: 'delete',
    url: `https://jpadadle86.execute-api.eu-north-1.amazonaws.com/Prod/api/S3BucketTools/DeleteFileFromS3Bucket`,
    headers: {    
    'accept':'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${jwt}`
    },
    data:data
        };
    
    await axios(config,data)
    .then(function (response:any) {
        if(response.status===204)
            dispatch({type:FILE_DELETED})
    })
    .catch(function (error:any) {
    dispatch({type:FILE_DELETITION_FAIL,payload:String(error)})
    });
}