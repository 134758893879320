import { Box, Container, Grid } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { AiOutlineFile, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import CircularProgress from '@mui/material/CircularProgress';
import './File.css'
interface IBucketDialogProp {
    name:string,
    fileKey:String,
    commitDelete:(key:String)=>void,
    deletingFile:boolean,
    isFiledeleted:boolean
}
export const File: React.FC<IBucketDialogProp> = ({name,fileKey,commitDelete,deletingFile,isFiledeleted}: IBucketDialogProp) => {
    
    return (
        <div className='filediv' ><AiOutlineFile fontSize='small'/>{name}
            <div className='fileDeleteDiv'>
             <AiOutlineDelete onClick={()=>commitDelete(fileKey)} />
              {/* {deletingFile && <div className='loader'></div>} */}
            </div>
        </div>
        )


}   

