import React, { useEffect, useState } from 'react';
import logo2 from './wsp.svg'
import '@aws-amplify/ui-react/styles.css';
import logo from './logo.svg';
import { Box, Container, Grid,Button } from '@mui/material';
import './App.css';
import {withAuthenticator,Authenticator,useAuthenticator} from '@aws-amplify/ui-react';
import ViewPort from './components/ViewPort/ViewPort';
import FloatingMenu from './components/FloatingMenu/FloatingMenu';
import Amplify from 'aws-amplify';
import { Auth } from "aws-amplify";
import appConfig from "./appconfig.json";
import { Navigate, useParams } from 'react-router-dom'
import awsConfig from "./aws-exports"
import { useDispatch } from 'react-redux';
import { loadProjectDetails, loadUserRole } from './store/actions/projectActions';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import Organize from './components/organize/Organize';
import PageNotFound from './components/organize/PageNotFound';
const components={
  Header() {
    // const { tokens } = useTheme();

    return (
      <Grid  textAlign='center'>
        <img
          alt="Amplify logo"
          src={logo2} height="60" width="60"
        />
      </Grid>
    );
  },

  Footer() {

    return (
      <Grid textAlign="center" >
     WSP &copy; All Rights Reserved
    </Grid>
     
    );
  },

  SignIn:{
    Header(){
      return(
        <Grid textAlign={'center'} sx={{fontWeight:'bold',m:1,fontSize:'20px'}}>Sign In to your account</Grid>
      )
    },
   Footer(){
      const { toResetPassword } = useAuthenticator();
      return(
        <Grid container sx={{justifyContent:'flex-end',px:3,py:1}} ><Button  onClick={toResetPassword} style={{color:'var(--amplify-components-button-link-color)',fontWeight:'normal',fontSize:'var(--amplify-components-button-small-font-size)'}}>Forgot your password</Button></Grid>
      ) 
    }
  }
}
//console.log(process.env)

// Amplify Configuration
Amplify.configure(awsConfig)
Amplify.configure({awsConfig,
  Auth:{
    cookieStorage:{
      domain: process.env.REACT_APP_DOMAIN === undefined ? ".dimdocks.com" : process.env.REACT_APP_DOMAIN,
      expires:1,
      path:"/",
      secure:true
    }
  }
})

function App() {
  
  const [jwt,setJwt]=useState<string>("")
  const fetchProjectInfo = async () => { 
    try {
      Auth.currentSession().then((res:any)=>{
        let accessToken = res.getAccessToken()
        setJwt(accessToken.getJwtToken())
        })
    } catch(error) {
        console.error("Error: ", error)
    }
  }

  React.useEffect(() => {
    fetchProjectInfo()
  }, [])
  
  return (

    
    <div className="App">
      <div className="main">
      <Authenticator components={components} hideSignUp={true}>
            {({ signOut, user }) => (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<><PageNotFound /></>} />
            <Route path='/:projectName' element={<><ViewPort  token={jwt}/> </>}/>
            <Route path="/:projectName/organize" element={<Organize/>}/>
            <Route path="/:projectName//organize" element={<Organize/>}/>
            <Route path={`//*`} element={<Navigate to={window.location.pathname.slice(1)}/>}/>
            {/* <Route path="/:projectName/*" element={<PageNotFound />}/> */}
          </Routes>
        </BrowserRouter>   
            )}
              </Authenticator>
      </div>
    </div> 
  );
}

export default App;
// export default withAuthenticator(App);