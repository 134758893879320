/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSignedUrl = /* GraphQL */ `
  query GetSignedUrl($type: String, $filename: String) {
    getSignedUrl(type: $type, filename: $filename)
  }
`;
export const getDataType3dviewer = /* GraphQL */ `
  query GetDataType3dviewer($id: ID!) {
    getDataType3dviewer(id: $id) {
      id
      Type
      FileTypes
      RequestString
      Workspace
      Description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataType3dviewers = /* GraphQL */ `
  query ListDataType3dviewers(
    $filter: ModelDataType3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataType3dviewers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Type
        FileTypes
        RequestString
        Workspace
        Description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataType3dviewerByName = /* GraphQL */ `
  query GetDataType3dviewerByName(
    $Type: String
    $sortDirection: ModelSortDirection
    $filter: ModelDataType3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDataType3dviewerByName(
      Type: $Type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Type
        FileTypes
        RequestString
        Workspace
        Description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataSet3dviewer = /* GraphQL */ `
  query GetDataSet3dviewer($id: ID!) {
    getDataSet3dviewer(id: $id) {
      id
      DataSet
      Priority
      FmeServerFilePath
      SharedData
      MinTileSize
      FmeServerCached
      Resolution
      Description
      createdAt
      updatedAt
      Type {
        id
        Type
        FileTypes
        RequestString
        Workspace
        Description
        createdAt
        updatedAt
        __typename
      }
      Projects {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listDataSet3dviewers = /* GraphQL */ `
  query ListDataSet3dviewers(
    $filter: ModelDataSet3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataSet3dviewers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        DataSet
        Priority
        FmeServerFilePath
        SharedData
        MinTileSize
        FmeServerCached
        Resolution
        Description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataSet3dviewerByName = /* GraphQL */ `
  query GetDataSet3dviewerByName(
    $DataSet: String
    $sortDirection: ModelSortDirection
    $filter: ModelDataSet3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDataSet3dviewerByName(
      DataSet: $DataSet
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        DataSet
        Priority
        FmeServerFilePath
        SharedData
        MinTileSize
        FmeServerCached
        Resolution
        Description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoordinateSystem3dviewer = /* GraphQL */ `
  query GetCoordinateSystem3dviewer($id: ID!) {
    getCoordinateSystem3dviewer(id: $id) {
      id
      CoordinateSystem
      XMAX
      YMAX
      XMIN
      YMIN
      EPSG
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCoordinateSystem3dviewers = /* GraphQL */ `
  query ListCoordinateSystem3dviewers(
    $filter: ModelCoordinateSystem3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoordinateSystem3dviewers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CoordinateSystem
        XMAX
        YMAX
        XMIN
        YMIN
        EPSG
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoordinateSystem3dviewerByName = /* GraphQL */ `
  query GetCoordinateSystem3dviewerByName(
    $CoordinateSystem: String
    $sortDirection: ModelSortDirection
    $filter: ModelCoordinateSystem3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCoordinateSystem3dviewerByName(
      CoordinateSystem: $CoordinateSystem
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CoordinateSystem
        XMAX
        YMAX
        XMIN
        YMIN
        EPSG
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject3dviewer = /* GraphQL */ `
  query GetProject3dviewer($id: ID!) {
    getProject3dviewer(id: $id) {
      id
      ProjectName
      Origin_X
      Bounds
      Origin_Y
      Application
      StreamingURL
      createdAt
      updatedAt
      DataSets {
        nextToken
        __typename
      }
      CoordinateSystem {
        id
        CoordinateSystem
        XMAX
        YMAX
        XMIN
        YMIN
        EPSG
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listProject3dviewers = /* GraphQL */ `
  query ListProject3dviewers(
    $filter: ModelProject3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProject3dviewers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProjectName
        Origin_X
        Bounds
        Origin_Y
        Application
        StreamingURL
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject3dviewerByName = /* GraphQL */ `
  query GetProject3dviewerByName(
    $ProjectName: String
    $sortDirection: ModelSortDirection
    $filter: ModelProject3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProject3dviewerByName(
      ProjectName: $ProjectName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProjectName
        Origin_X
        Bounds
        Origin_Y
        Application
        StreamingURL
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPointsofinterest3dviewer = /* GraphQL */ `
  query GetPointsofinterest3dviewer($id: ID!) {
    getPointsofinterest3dviewer(id: $id) {
      id
      ProjectName
      Type
      Group
      Name
      Location
      Rotation
      AdditionalData
      createdAt
      updatedAt
      Project {
        id
        ProjectName
        Origin_X
        Bounds
        Origin_Y
        Application
        StreamingURL
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listPointsofinterest3dviewers = /* GraphQL */ `
  query ListPointsofinterest3dviewers(
    $filter: ModelPointsofinterest3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPointsofinterest3dviewers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProjectName
        Type
        Group
        Name
        Location
        Rotation
        AdditionalData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPointsofinterest3dviewerByProjectName = /* GraphQL */ `
  query GetPointsofinterest3dviewerByProjectName(
    $ProjectName: String
    $sortDirection: ModelSortDirection
    $filter: ModelPointsofinterest3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPointsofinterest3dviewerByProjectName(
      ProjectName: $ProjectName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProjectName
        Type
        Group
        Name
        Location
        Rotation
        AdditionalData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRuleSet3dviewer = /* GraphQL */ `
  query GetRuleSet3dviewer($id: ID!) {
    getRuleSet3dviewer(id: $id) {
      id
      ProjectName
      Name
      Type
      IsDefault
      createdAt
      updatedAt
      Rules {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listRuleSet3dviewers = /* GraphQL */ `
  query ListRuleSet3dviewers(
    $filter: ModelRuleSet3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRuleSet3dviewers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProjectName
        Name
        Type
        IsDefault
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRuleSet3dviewerByProjectName = /* GraphQL */ `
  query GetRuleSet3dviewerByProjectName(
    $ProjectName: String
    $sortDirection: ModelSortDirection
    $filter: ModelRuleSet3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRuleSet3dviewerByProjectName(
      ProjectName: $ProjectName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProjectName
        Name
        Type
        IsDefault
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRule3dviewer = /* GraphQL */ `
  query GetRule3dviewer($id: ID!) {
    getRule3dviewer(id: $id) {
      id
      ProjectName
      Identifiers
      Value
      Type
      Priority
      RuleSetName
      createdAt
      updatedAt
      RuleSet {
        id
        ProjectName
        Name
        Type
        IsDefault
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listRule3dviewers = /* GraphQL */ `
  query ListRule3dviewers(
    $filter: ModelRule3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRule3dviewers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ProjectName
        Identifiers
        Value
        Type
        Priority
        RuleSetName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobStatus3dviewer = /* GraphQL */ `
  query GetJobStatus3dviewer($id: ID!) {
    getJobStatus3dviewer(id: $id) {
      id
      FMEJobId
      ErrorMessage
      WarningMessage
      FIleNameKey
      Status
      UserName
      ProjectName
      TransformScript
      StartTime
      UpdateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listJobStatus3dviewers = /* GraphQL */ `
  query ListJobStatus3dviewers(
    $filter: ModelJobStatus3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobStatus3dviewers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        FMEJobId
        ErrorMessage
        WarningMessage
        FIleNameKey
        Status
        UserName
        ProjectName
        TransformScript
        StartTime
        UpdateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJobStatus3dviewerByProjectName = /* GraphQL */ `
  query GetJobStatus3dviewerByProjectName(
    $ProjectName: String
    $sortDirection: ModelSortDirection
    $filter: ModelJobStatus3dviewerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getJobStatus3dviewerByProjectName(
      ProjectName: $ProjectName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        FMEJobId
        ErrorMessage
        WarningMessage
        FIleNameKey
        Status
        UserName
        ProjectName
        TransformScript
        StartTime
        UpdateTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
