
import * as React from 'react';
import { Alert, Checkbox, FormControlLabel, LinearProgress, Tooltip } from '@mui/material';
import { useState,useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {Auth} from "aws-amplify"
import { Box, Container, Grid } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button';
import  FileList  from './FileList';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { FormLabel } from '@mui/material';
import { listDataType3dviewers } from '../../graphql/queries';
import { connect, useDispatch } from 'react-redux';
import AddDataSetDialog from '../UploadDialog/AddDataSetDialog';
import {AlertDialog} from './AlertDialog'
import { uploadFile } from '../../store/actions/fileUploadActions_new';
import { s3BucketView } from '../../store/actions/ViewS3BucketActions';
import { CreateDataSet3dviewerInput, CreateProjectDataSet3dviewerInput, DataSet3dviewer, Project3dviewer } from '../../API';
import { createDataSet3dviewer, createProjectDataSet3dviewer } from '../../graphql/mutations';
import { TurnedInOutlined } from '@mui/icons-material';
import { loadProjectDetails, loadUserRole } from 'store/actions/projectActions';
interface IOrganize {
    id?: string
    projectInfo?: Project3dviewer,
}

const Input = styled('input')({
    display: 'none',
  });


type DataSetListItemType = {
    label: string;
    dataSet: DataSet3dviewer;
  }
  interface IUploadDialogProp {
    projectInfo?: Project3dviewer,
    isProjectLoaded?: boolean,
    isUploading?: boolean[],
    isUploaded?: boolean[],
    uploadProgress?: number[] ,
    result?: any[],
    error?: any[],
    isFiledeleted?:boolean,
}
type DataSetListItemTypes = DataSetListItemType[]
 const DataSet:React.FC<IUploadDialogProp> = ({projectInfo, ...stateProps}: IUploadDialogProp) => {
    
    const [initialized, setInitialized] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false)
    const [browseFilesAllowed, setBrowseFilesAllowed] = useState<boolean>(false)


    // Controls
    const [dataSetListItems, setDataSetListItems] = useState<DataSetListItemTypes>([]);
    const [selectedDatSourceListItem, setSelectedDatSourceListItem] = useState<DataSetListItemType | null | undefined>(null);
    const [shared, setShared] = useState<boolean | null | undefined>(false);
    const [dataType, setDataType] = useState<String | null | undefined>("");
    const [group, setGroup] = useState<String>("");
    const [DATASET,SETDATASET]=useState<String>("")
    // File Controls
    const [file, setFile] = React.useState<any>("")
    const [progress, setProgress] = useState<string>("");
    const [uploaded, setUploaded] = useState<boolean>(true);

    const [openAddDsDialog, setOpenAddDsDailog] = useState<boolean>(false); 
    const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false); 
    const [jwt, setJwt] = useState<string>("")

    
    const [existingDataSets, setExistingDataSets] = useState<DataSet3dviewer[]>([])
    const dispatch = useDispatch()

    const [selectedFile, setSelectedFile] = useState<any | null>([]);
    const [isSelected, setIsSelected] = useState(false);
    const [FileTypes,setFileTypes]=useState("")
    const [fileTypesArray,setFileTypesArray]=useState<String[]>([])
   
    React.useEffect(() => {
        stateProps.isUploading && setUploaded(false)
        stateProps.isUploaded && setUploaded(true)
        stateProps.isProjectLoaded && updateControls()
        stateProps.isFiledeleted && bucketview(dataType!,DATASET)
        initialize()    
        validate()
        if(selectedFile==null || (selectedFile && Array.from(selectedFile).length<1))
            setIsSelected(false)
    }, [
      stateProps.isUploading, stateProps.isUploaded, stateProps.error, stateProps.uploadProgress,
      stateProps.isProjectLoaded,stateProps.isFiledeleted,
      file, group,selectedFile
    ])
    
    const testing=()=>{
      //console.log(dataType," ",DATASET)
    }

    Auth.currentSession().then((res:any)=>{
      let accessToken = res.getAccessToken()
      //console.log(accessToken.getJwtToken())
      setJwt(accessToken.getJwtToken())
      })
    function onDelete(file: any) {
        const filteredFiles=Array.from(selectedFile).filter((files:any)=>files.name!==file.name)
        setSelectedFile([...filteredFiles])
      }

      const validate = () => {
        const isValid = selectedDatSourceListItem && group.length > 0 && selectedFile && selectedFile.length>0
        setValidated(isValid)
      }

      const initialize = () => {
        

        if(initialized) return
        setInitialized(true)
        updateControls()
      }
  
      const updateControls = () => {
        if(dataSetListItems.length > 0) return;
        if(projectInfo && projectInfo.DataSets && projectInfo.DataSets.items) {
          projectInfo.DataSets.items.forEach((item: any) => {
            if(item.dataSet && item.dataSet.DataSet){
                const listItem: DataSetListItemType = {
                  label: item.dataSet.DataSet,
                  dataSet: item.dataSet
                }
            
              if(listItem && !listItem.dataSet.SharedData)      //Taking only non-shared DataSets
                  dataSetListItems.push(listItem)
              }
            })
        }
      }
      
      const bucketview=( DATATYPE:String,set:String)=>{
        let s3Path = "";
      
        let projectName = shared ? "Shared" : projectInfo!.ProjectName;
        
        // Project Specific or Shared Path
        s3Path += projectName
    
        // Source Data
        s3Path += "%2fSourceData"
  
        // Coordinate System
        s3Path += "%2f" + projectInfo!.CoordinateSystem!.CoordinateSystem
  
        // DataType
        s3Path += "%2f" + DATATYPE
  
        // DataSet
        s3Path += "%2f" + set
        dispatch(s3BucketView(s3Path,jwt))
      }

      const onDataSetChange = async(e: any, value: DataSetListItemType | null) => {
        if(!value) return
        SETDATASET(value.label)
        if(selectedFile.length==0){
          setBrowseFilesAllowed(value?true:false)                      
         }
        else if(selectedFile.length>0){
          const status:boolean= window.confirm("DataSet change detected, all the selected files will be discarded. Press OK to continue.");
          if (status == true) {  
            setSelectedFile([])
          } else {    
            return
          }
        } 
        setSelectedDatSourceListItem(value)
        setShared(value.dataSet.SharedData)
        if(value.dataSet.Type){var temp=value.dataSet.Type.Type
          setDataType(value.dataSet.Type.Type)}
         bucketview(value.dataSet.Type!.Type,value.label)

        

        const response:any = await API.graphql(graphqlOperation(listDataType3dviewers))
       response && Array.from(response.data.listDataType3dviewers.items).map((res:any)=>{
            if(res.Type===temp && res.FileTypes!==null)
                {
                    let typesOfFile="."
                    typesOfFile+=res.FileTypes.join(',.')
                    setFileTypesArray(res.FileTypes)
                    setFileTypes(typesOfFile) } 
                  })
        
      }
  
      const onFileChange = (e: any) => {
        setUploaded(false)
        setFile(e.target.files[0])
        setIsSelected(true);
        let flag=false

        let files= (e.target as HTMLInputElement).files || [];
        for(let file of Array.from(files)){
          const exten=file.name.split('.').pop()!
            if(fileTypesArray.indexOf(exten) == -1)
              {
                flag=true
                break                
              }            
        }
        if(flag){
          setOpenAlertDialog(true)
          return
        }
          
        if(selectedFile && selectedFile.length>0)
          {
            // selectedFile.some( ({file:any}) => file.name == roleParam)  
            var new_array=Array.from(selectedFile).concat(Array.from(files)); 

             new_array = Array.from(new_array).filter(function(file:any, index:number, self:any) {
              return index === self.indexOf(file);
          })
            setSelectedFile(new_array)
            
          }
        else
          setSelectedFile(files)
         
        
          
      }
  
      const onGroupChange = (event: any) => {
        setGroup(event.target.value)
      }
  
      const updateDataSet = async () => {
        return new Promise(async (resolve, reject) => {
          debugger;
          let dataSet = null
        
          selectedDatSourceListItem ?
            dataSet = selectedDatSourceListItem.dataSet :
            reject("DataSet must be specified.");
          
          // Ignore TypeScript Errors
          if(!projectInfo || !dataSet) return
  
          // Existing dataSet
          if(dataSet.id) {
            resolve(dataSet)
            return
          }
    
          // Need to create new DataSet db instance
          try {
            let dataSetId = null
            let typeId = dataSet.Type ? dataSet.Type.id : reject("DataType undefined.")
      
            const dsInputs: CreateDataSet3dviewerInput = {
              DataSet: dataSet.DataSet,
              dataSet3dviewerTypeId: "" + typeId
            }
  
            // Create DataSet operation
            let response:any = await API.graphql(graphqlOperation(createDataSet3dviewer, {input: dsInputs}))
            dataSet = response.data.createDataSet3dviewer
            dataSetId = response.data.createDataSet3dviewer.id
  
            // Update Relation Operation
            const projectDsInputs: CreateProjectDataSet3dviewerInput = {
              projectDataSet3dviewerProjectId: projectInfo.id,
              projectDataSet3dviewerDataSetId: dataSetId
            }
  
            // Update relational table
            response = await API.graphql(graphqlOperation(createProjectDataSet3dviewer, {input: projectDsInputs}))
            resolve(dataSet)
          }
          catch (error) {
            reject(error)
          }
        })
      }
  
      const onUpload =  async (event: any) => {
        if(!projectInfo || !projectInfo.CoordinateSystem || !selectedDatSourceListItem) return
        
        
        let s3Path = "";
      
        let projectName = shared ? "Shared" : projectInfo.ProjectName;
        
        // Project Specific or Shared Path
        s3Path += projectName
    
        // Source Data
        s3Path += "/SourceData"
  
        // Coordinate System
        s3Path += "/" + projectInfo.CoordinateSystem.CoordinateSystem
  
        // DataType
        s3Path += "/" + dataType
  
        // DataSet
        s3Path += "/" + selectedDatSourceListItem.dataSet.DataSet
  
        // Group
        s3Path += "/" + group
  
        //console.log(s3Path)
  
        try {
          await updateDataSet()
          await dispatch(uploadFile(s3Path,selectedFile,FileTypes))
          bucketview(dataType!,DATASET)
        }
        catch (error) {
          //console.log(error)
        }
      }
      
  
      const onAddDsButton = () => {
        dataSetListItems.map(item => existingDataSets.push(item.dataSet))
        setOpenAddDsDailog(true)
      }
  
      const handleAddDsDialogClose = () => {
        setOpenAddDsDailog(false)
      }
      const handleAlertDialogClose=()=>{
        setOpenAlertDialog(false)
      }
      const handleDsCreate = (dataSet: DataSet3dviewer) => {
        const dsItem: DataSetListItemType = {
          label: dataSet.DataSet,
          dataSet: dataSet
        }
        setDataSetListItems([...dataSetListItems, dsItem])
        setSelectedDatSourceListItem(dsItem)
        setShared(dataSet.SharedData)
        if(dataSet.Type) 
        {setDataType(dataSet.Type.Type)}
        onDataSetChange(dsItem,dsItem)
      }
    return (
        <>
        <Grid item  xs={12} md={8} lg={6}  sx={{py:2}}>
        <Grid item container justifyContent= 'flex-start' alignContent='center' >
            <Grid  item container md={11} xs={12} sx={{py:1,pl:2}}  >
              <Grid>
              <FormLabel style={{marginBottom:'2vh'}}>Add DataSet:</FormLabel>
              
                <Tooltip title="Create new data Set" placement="left-start">
                  <IconButton 
                    color="primary" 
                    aria-label="Add New Data Set"   
                    component="span"
                    size="large"
                    onClick={onAddDsButton}
                  >
                    <AddBoxIcon />
                  </IconButton>
                  </Tooltip>
              </Grid>
            <Autocomplete disablePortal fullWidth
            id="combo-box-demo" 
            options={dataSetListItems}
            onChange={onDataSetChange}
            sx={{ maxwidth: 300 }}
            renderInput={(params) => <TextField required {...params} label="Add DataSet" />}
            value={selectedDatSourceListItem}
            />
            </Grid>
            
            {/* <Grid item  xs={4} sx={{pt:0.5}}> //Shared Input
                <FormControlLabel
                  control={                    
                    <Checkbox name="shared" checked={shared ? true : false}/>
                  }
                  label="Shared"    
                  disabled={true}
                />    
            </Grid> */}
        </Grid>
        <Grid item container md={11} xs={12} sx={{pl:2,py:2}}  >
        <FormLabel style={{marginBottom:'2vh'}}>DataType:</FormLabel>
            <TextField disabled={true} value={dataType} fullWidth id="outlined-basic" label="DataType"  variant="outlined" required/>
        </Grid>
        <Grid item container  md={11} xs={12} sx={{pl:2,py:2}} >
        <FormLabel style={{marginBottom:'2vh'}}>File Location:</FormLabel>

            <TextField fullWidth id="filled-basic" value={group}
                  onChange={onGroupChange} label="GroupName" variant="outlined" required />
        </Grid>
        
        
        <AddDataSetDialog 
          open={openAddDsDialog}
          existingDataSets={existingDataSets}
          onCreate={handleDsCreate} 
          onClose={handleAddDsDialogClose}/>
        <AlertDialog open={openAlertDialog} fileTypesArray={fileTypesArray} onClose={handleAlertDialogClose} />    

          </Grid>
         <Grid item xs={12} lg={6} md={8}  data-testid="file-list" sx={{border:1,borderRadius:2,height:'auto',maxHeight: '40vh', overflow: 'auto',py:3,my:4}}  >    
             <b>Selected Files</b><br />
             {!isSelected && <h3>No Files Selected</h3>}
             {isSelected &&  <FileList files={selectedFile} onDelete={onDelete} />}
        </Grid>
        <Grid className='browseUploadGrid' item container xs={12} sx={{mb:1,minHeight:'15vh', alignSelf: 'flex-end',justifyContent:'flex-end'}}  direction="row"  >
            <Grid item container xs={12} sm={5} md={4} lg={3}   sx={{bgcolor:'',alignItems: 'center',justifyContent: 'space-around'}} title="First select DataSet to browse files" >
                <label htmlFor="contained-button-file">
                <Input type="file" id="contained-button-file" className='button' onChange={onFileChange} accept={FileTypes} multiple    disabled={!browseFilesAllowed}/>
                <Button variant="contained" size="large" component="span"  disabled={!browseFilesAllowed} >
                Browse Files
                </Button>
                </label>
            </Grid>
            {browseFilesAllowed  && FileTypes.length>0 && <Grid sx={{alignSelf:'flex-end',position:'absolute',color:'red'}}>*Please select {FileTypes} files only</Grid>}
            <Grid item container xs={12} sm={4} md={2} title="First select DataSet, GroupName and Files to upload" sx={{justifyContent: 'center',alignItems: 'center'}}>
             <Button autoFocus variant="contained" onClick={onUpload} disabled={!validated} >
                    Upload
                </Button>
            </Grid> 
        </Grid> 
      </>
  )
         
};

const mapStateToProps = (state: any) => {
    return {
      projectInfo: state.ProjectDetail.projectInfo,
      isProjectLoaded: state.ProjectDetail.isLoaded,
      isUploading: state.FileUploads.isUploading,
      isUploaded: state.FileUploads.isUploaded,
      uploadProgress: state.FileUploads.uploadProgress,
      result: state.FileUploads.result,
      error: state.FileUploads.error,
      isFiledeleted:state.BucketView.isFiledeleted
  
    }
  }
  
  export default connect(mapStateToProps, {})(DataSet);
