
import { USER_ROLE_IN_PROJECT_LOADING, USER_ROLE_IN_PROJECT_LOADED, USER_ROLE_IN_PROJECT_FAIL } from "../types/projectDetailTypes";
import { Project3dviewer } from "../../API"

export interface IUserDetail {
    isLoading: boolean
    isLoaded: boolean
    error: string
    userRole:string | null
}

const InitialUserDetailsState: IUserDetail = {
    isLoading: false,
    isLoaded: false,
    userRole:null,
    error: ""
}

const UserRoleReducer = (state: IUserDetail = InitialUserDetailsState, action: any) => {
    switch(action.type) {
        case USER_ROLE_IN_PROJECT_LOADING:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        case USER_ROLE_IN_PROJECT_LOADED:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                userRole:action.payload
            }
        case USER_ROLE_IN_PROJECT_FAIL:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: action.payload
            }

        default:
            return state;
    }
}

export default UserRoleReducer;