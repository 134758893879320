export const userRoleQuery = /* GraphQL */ `
  query ListDimsPortals(
    $filter: TableDimsPortalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDimsPortals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ProjectId
        ProjectName
        GroupDescription
        GroupId
        GroupName
        GroupStatus
        ProjectDescription
        ProjectId
        ProjectName
        ProjectStatus
        ServiceAssignedDate
        ServiceId
        ServiceUrl
        ServiceName
        ServiceTemplateValues
        UserEmail
        UserId
        UserName
        CreatedOn
        CreatedBy
        ModifiedOn
        ModifiedBy
        pk
        sk
      }
      nextToken
    }
  }
`;
export const queryDimsPortalsByUsernames = /* GraphQL */ `
  query QueryDimsPortalsByUsernames(
    $UserId: String!
    $first: Int
    $after: String
  ) {
    queryDimsPortalsByUsernames(UserId: $UserId, first: $first, after: $after) {
      items {
        ProjectId
        ProjectName
        UserName
        ProjectStatus
        UserId
        pk
        sk
        ServiceUrl
      }
      nextToken
    }
  }
`;