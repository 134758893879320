import { Box, CircularProgress, Container, Grid } from '@mui/material';
import { height } from '@mui/system';
import { Project3dviewer } from 'API';
import * as React from 'react';
import  DataSet  from './DataSet';
import { connect, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {Error} from './Error'
import TreeRecursive from '../BucketView/TreeRecursive';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useParams } from 'react-router-dom'
import FloatingMenu from '../FloatingMenu/FloatingMenu';
import { BreadCrumb } from './BreadCrumb';
import './FileList.css'
import logo from './wsp.svg'
import { loadProjectDetails, loadUserRole } from 'store/actions/projectActions';
import { AccessDenied } from './AccessDenied';
import PageNotFound from './PageNotFound';
import { emptyKeyArray, getAllJobStatus, translation } from 'store/actions/translateFileActions';
import TranslateFilesGrid from './TranslateFilesGrid';
interface dataStructure{
    name:string,
    key:string,
    children:dataStructure[],
  }
interface IUploadDialogProp {
    projectInfo?: Project3dviewer,
    isProjectLoading?:boolean,
    isProjectLoaded?: boolean,
    isUploading?: boolean[],
    isUploaded?: boolean[],
    isTreeLoading:boolean,
    uploadProgress?: number[],
    result?: any[],
    jobsLoaded:boolean,
    jobsLoading:boolean,
    error?: string[],
    keyArray:string[],
    isTree?:boolean,
    tree?:dataStructure,
    isFiledeleted?:boolean,
    deletingFile?:boolean,
    fileNames:string[],
    isuserRoleLoading:boolean,
    isuserRoleLoaded:boolean,
    userRole:string | null,
    jobsData:any[]
}

const Organize: React.FC<IUploadDialogProp> = ({projectInfo, ...stateProps}: IUploadDialogProp) => {
    // console.log(stateProps.tree)

    let {projectName}=useParams()

let checker = (arr:boolean[]) => arr.every((v:boolean) => v === true);
const dispatch=useDispatch()
const fetchProjectInfo = async () => { 
    try {
      dispatch(loadProjectDetails(projectName))
      dispatch(loadUserRole(projectName))
    } catch(error) {
        console.error("Error: ", error)
        projectName="Error";
    }
  }
React.useEffect(()=>{
    fetchProjectInfo()
},[])
React.useEffect(()=>{
    if(projectInfo)
        {
            dispatch(getAllJobStatus(projectInfo.ProjectName))
            setInterval(()=>{
                dispatch(getAllJobStatus(projectInfo.ProjectName)) //calling after every 60 seconds
            },60000)
            }
    dispatch(emptyKeyArray()) //Making the keys as empty array becaus there are no files selected for translation when website is opened
},[dispatch, projectInfo])


    return (
        <>
        {(stateProps.isProjectLoading  || stateProps.isTreeLoading || stateProps.deletingFile || stateProps.jobsLoading ) && <Grid sx={{position:'absolute',top:'50%',left:'50%'}}><CircularProgress /></Grid>}
        {!stateProps.isProjectLoading && !projectInfo && <PageNotFound />}
        {stateProps.isProjectLoaded && stateProps.isuserRoleLoading && <Grid sx={{position:'absolute',top:'50%',left:'50%'}}><CircularProgress /></Grid>}
        {stateProps.isProjectLoaded===true && stateProps.isuserRoleLoaded && (stateProps.userRole===null || stateProps.userRole==="Reader") && <AccessDenied />}
        {stateProps.isProjectLoaded===true && stateProps.isuserRoleLoaded && stateProps.userRole!==null && (stateProps.userRole==="Writer")  && stateProps.isProjectLoaded &&  <>
    <Box className='box' sx={{ display:'flex', flexWrap: 'wrap',margin:'0px',flexDirection:'column',filter:(stateProps.isTreeLoading || stateProps.jobsLoading || stateProps.deletingFile)?'blur(6px)':'none',pointerEvents:(stateProps.isTreeLoading || stateProps.deletingFile || stateProps.jobsLoading)?'none':'auto'}} >
        <Grid item  xs={12} sx={{alignItems:'center',display:'flex',p:1,borderBottom:'1px solid lightgray'}}>
           <img style={{alignContent:'flex-start',display:'flex'}} src={logo}  alt="WSP"  width='50' height='40' />
           <Grid item container sx={{justifyContent:'center'}}><b >{projectInfo ? projectInfo.ProjectName : "Loading..."}</b></Grid>
        </Grid>
        {/* <Grid item xs={12}  sx={{backgroundColor:'lightgray',color: 'black',p:1.5}}>
            <BreadCrumb projectName={projectInfo ? projectInfo.ProjectName : "Loading..."}/>               
        </Grid> */}
        <Grid sx={{minHeight:'75vh'}} item  justifyContent="center" container direction="row" >
            <Grid  item container  sm={8} xs={12}    sx={{px:0.5 }} direction='row' >
                {stateProps.error &&   stateProps.error.length>0 &&  <Grid item sm={12} >
                <Error error={stateProps.error} />
                </Grid>}    
                <DataSet />
            </Grid>
            <Grid item container md={4} xs={12} sx={{justifyContent:'center'}}>
                <Grid item  xs={12} sx={{justifyContent: 'center'}}><b>Directory View</b></Grid>
                {!stateProps.isTree && !stateProps.isTreeLoading && <Grid item xs={6} sm={12}  sx={{}}>
                    <Grid item ><FormatListBulletedIcon fontSize='large' color='primary'/></Grid>
                    Please select any dataSet to see the bucket view
                </Grid>} 
                {stateProps.isTree && !stateProps.isTreeLoading && stateProps.tree!.children!.length===0 && <Grid item   xs={6} sm={12} sx={{justifyContent:'center',fontWeight:'bold'}}>
                    <Grid item><FormatListBulletedIcon fontSize='large' color='primary'/></Grid>There are no files in this dataSet.
                </Grid>}
                {stateProps.isTree && !stateProps.isTreeLoading && stateProps.tree!.children!.length>0 && <Grid item  container xs={10} sx={{justifyContent:'center',overflow:'auto',height:'50vh',my:1,}} >
                    <TreeRecursive keyArray={stateProps.keyArray} jobsData={stateProps.jobsData} structure={stateProps.tree!} fileNames={stateProps.fileNames} isFiledeleted={stateProps.isFiledeleted!}  deletingFile={stateProps.deletingFile!} /></Grid>}
                {stateProps.isUploaded!.length>0 && checker(stateProps.isUploaded!) && <Grid item container xs={3} onClick={()=>{dispatch(translation(stateProps.keyArray.join(','),projectInfo!.ProjectName))}} md={12} sx={{alignSelf:'flex-end',justifyContent: 'flex-end',alignItems:'center',m:1,mr:2,height:'15vh'}}><Button  variant="contained" >
                    Submit
                </Button></Grid>}
            </Grid>
            <Grid item xs={11} ><TranslateFilesGrid/></Grid>
        </Grid>
        
        <Grid className='footer'  item  xs={12} container sx={{}}>
                Copyright &#169; WSP Global
            </Grid> 
    </Box>
    <FloatingMenu onUploadPage={true}/>
    </>}
        
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    projectInfo: state.ProjectDetail.projectInfo,
    isProjectLoading:state.ProjectDetail.isLoading,
    isProjectLoaded: state.ProjectDetail.isLoaded,
    isUploading: state.FileUploads.isUploading,
    isUploaded: state.FileUploads.isUploaded,
    uploadProgress: state.FileUploads.uploadProgress,
    result: state.FileUploads.result,
    error: state.FileUploads.error,
    isTree:state.BucketView.isTree,
    isTreeLoading:state.BucketView.isTreeLoading,
    fileNames:state.FileUploads.fileNames,
    tree:state.BucketView.tree,
    isFiledeleted:state.BucketView.isFiledeleted,
    keyArray:state.translateReducer.keyArray,
    jobsLoading:state.translateReducer.jobsLoading,
    jobsLoaded:state.translateReducer.jobsLoaded,
    jobsData:state.translateReducer.jobsData,
    deletingFile:state.BucketView.deletingFile,
    userRole:state.UserRoleReducer.userRole,
    isuserRoleLoading:state.UserRoleReducer.isLoading,
    isuserRoleLoaded:state.UserRoleReducer.isLoaded
  }
}

export default connect(mapStateToProps, {})(Organize);