import { FILE_UPLOADED, FILE_UPLOADED_FAIL, FILE_UPLOADING, UPLOAD_DIALOG_CLOSED, UPLOAD_PROGRESS } from "../types/uploadsTypes";

export interface IFileUpload {
    file: FileList | null
    isUploading: boolean[]
    isUploaded: boolean[]
    fileNames:string[],
    uploadProgress: Number[]
    result: any[]
    error: string[]
}

const InitialFileUploadState: IFileUpload = {
    isUploading: [],
    isUploaded: [],
    file: null,
    fileNames:[],
    uploadProgress: [],
    result: [],
    error: []
}

const FileUploads = (state: IFileUpload= InitialFileUploadState, action: any) => {
    switch(action.type) {
        case FILE_UPLOADING:
            const isUploading_array=[...state.isUploading]
            const isUploaded_array=[...state.isUploaded]
            const uploadProgress_array=[...state.uploadProgress]
            uploadProgress_array[action.index]=0
            isUploading_array[action.index]=true
            isUploaded_array[action.index]=false
            return {
                ...state,
                isUploading: isUploading_array,
                isUploaded: isUploaded_array,
                uploadProgress:uploadProgress_array,
                file: action.payload
            };
        case FILE_UPLOADED:
            const new_isUploading=[...state.isUploading]
            const new_isUploaded=[...state.isUploaded]
            const new_result=[...state.result]
            new_result[action.index]=action.payload
            new_isUploading[action.index]=false
            new_isUploaded[action.index]=true
            return {
                ...state,
                isUploading: new_isUploading,
                fileNames:action.fileNames,
                isUploaded: new_isUploaded,
                result: new_result,
            }
        case UPLOAD_PROGRESS:
            const new_uploadProgress=[...state.uploadProgress]
            new_uploadProgress[action.index]=action.payload
            return {
                ...state,
                uploadProgress: new_uploadProgress,
            }
        case UPLOAD_DIALOG_CLOSED:
            return {
                ...InitialFileUploadState
            }
        case FILE_UPLOADED_FAIL:
            const new_isUploading_array=[...state.isUploading]
            const new_isUploaded_array=[...state.isUploaded]
            const new_error=[...state.error]
            new_error[action.index]=action.payload
            new_isUploading_array[action.index]=false
            new_isUploaded_array[action.index]=false
            return {
                ...state,
                isUploading: new_isUploading_array,
                isUploaded: new_isUploaded_array,
                error: new_error,
            }
        default:
            return state;
    }   
}

export default FileUploads;