import { API, Auth, graphqlOperation } from "aws-amplify";
import { deleteJobStatus3dviewer } from "graphql/mutations";
import { getJobStatus3dviewerByProjectName,listJobStatus3dviewers } from "graphql/queries";
import appConfig from "../../appconfig.json";
import { CHANGE_TRANSLATE_KEYS,JOBS_LOADED,JOBS_LOADING,JOBS_LOAD_FAIL,TRANSLATING_FILE_STARTED,EMPTY_KEYS } from "store/types/translateTypes";
var axios = require('axios');
export const getAllJobStatus=(projectName:String)=>async(dispatch:Function)=>{
    try{
    dispatch({type:JOBS_LOADING})
    // const res=await Auth.currentSession()
    // const jwt=res.getAccessToken().getJwtToken()
    // console.log(jwt)
    let response:any = await API.graphql(graphqlOperation(getJobStatus3dviewerByProjectName, {ProjectName: projectName,limit:500}))
    // let r:any = await API.graphql(graphqlOperation(listJobStatus3dviewers, ))

    // console.log(r.data.listJobStatus3dviewers.items)

    let jobArray:any[]=response.data.getJobStatus3dviewerByProjectName.items
    while (response.data.getJobStatus3dviewerByProjectName.nextToken!==null ){
        response = await API.graphql(graphqlOperation(getJobStatus3dviewerByProjectName,{limit:500,Projectname:projectName,nextToken:response.data.getJobStatus3dviewerByProjectName.nextToken})) 
        jobArray=jobArray.concat(response.data.listDimsPortals.items)
      }
    dispatch({type:JOBS_LOADED,payload:jobArray})
    }catch(error:any){
        console.log(error)
    dispatch({type:JOBS_LOAD_FAIL,payload:error.errors[0]})
    }
}
export const storingTranslateKeys=(key:string)=>async(dispatch:Function)=>{
    dispatch({type:CHANGE_TRANSLATE_KEYS,payload:"3dviewer-bucket-dev/"+key})
}
export const emptyKeyArray=()=>async(dispatch:Function)=>{
    dispatch({type:EMPTY_KEYS})
}

export const translation=(keyString:string,projectName:string)=>async (dispatch:Function)=>{
    dispatch({type:TRANSLATING_FILE_STARTED})
    const res=await Auth.currentSession()
    const user = await Auth.currentAuthenticatedUser()
    const jwt=res.getAccessToken().getJwtToken()
    const currentEnv = process.env.REACT_APP_3DVIEWER_ENV === undefined ? "dev": process.env.REACT_APP_3DVIEWER_ENV ;
    var data =JSON.stringify({
      "enV_NAME":"3dviewer-"+currentEnv,
      "s3SourcePaths":keyString,
      "userName":user.username,
      "projectname":projectName
    })
    //console.log(data)
    var config = {
    method: 'post',
    url: `https://jpadadle86.execute-api.eu-north-1.amazonaws.com/Prod/api/ThreeDViewerAsync/TranslateMaster`,
    headers: {    
    'accept':'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${jwt}`
    },
    data:data
        };
    await axios(config,data)
    .then(function (response:any) {
        emptyKeyArray()
        dispatch(getAllJobStatus(projectName))
        console.log(response)
        if(response.status===204)
            console.log(response)
            // dispatch({type:FILE_DELETED})
    })
    .catch(function (error:any) {
        console.log(error)
        emptyKeyArray()
    // dispatch({type:FILE_DELETITION_FAIL,payload:String(error)})
    });
}
export const deleteJob=(jobId:string)=>async(dispatch:Function)=>{
    try{
        const dsInputs={
            id:jobId
        }
    let response:any = await API.graphql(graphqlOperation(deleteJobStatus3dviewer,{input:dsInputs} ))
    }
    catch(error:any){
        console.error(error)
    }
}