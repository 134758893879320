import { API, graphqlOperation } from "aws-amplify";
import {v4 as uuidv4} from "uuid"
import axios from "axios";
import { getSignedUrl } from "../../graphql/queries";
import { FILE_UPLOADED, FILE_UPLOADED_FAIL, FILE_UPLOADING, UPLOAD_DIALOG_CLOSED, UPLOAD_PROGRESS } from "../types/uploadsTypes";
import { resolve } from "dns";




export const uploadFile = (s3Path: string, files: FileList,FileTypes:string) => async(dispatch: Function) => {
    let fileNamesArray:string[]=[]
    await Promise.all(Array.from(files).map(async(file:any,index:number)=>{
        try {   
            const exten=file.name.split('.').pop()!
            fileNamesArray.push(file.name)
            if(FileTypes.indexOf(exten) === -1)
                throw new Error("File extention of "+file.name+" does not match with required extention.")
             dispatch({type: FILE_UPLOADING, payload: files,index:index})
            
    
            const absoluteFilePath = s3Path + "/" + file.name
            const response:any = await API.graphql(graphqlOperation(getSignedUrl, {type: "getSignedUrl", filename: absoluteFilePath}))
            const data = JSON.parse(response.data.getSignedUrl)

            if(data.statusCode !== 200) throw new Error(data.error);
    
            const signedUrl = JSON.parse(data.body)

            const config = {
                headers: {
                    'Content-type': 'application/octet-stream'
                },
                onUploadProgress: (progressEvent:any) => {
                    const percent = Number((progressEvent.loaded * 100 / file.size).toFixed())
                    percent < 100 && dispatch({type: UPLOAD_PROGRESS, payload: percent,index:index})
                }
            }

            const result = await axios.put(signedUrl, file, config)
            dispatch({type: FILE_UPLOADED, payload: result,index:index,fileNames:fileNamesArray})
        }
        catch (error) {
            dispatch({type: FILE_UPLOADED_FAIL, payload:String(error),index:index})
        }
    }))


}

