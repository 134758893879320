import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
interface AlertOptions {
    open: boolean,
    onClose(): void,
    filename:string
}

export const ImpossibleDeleteAlert: React.FC<AlertOptions> = ({open,onClose,filename}: AlertOptions) => {   

      
    const handleClose = () => {
        onClose()
    };
    return(
      <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"File Deletion Failed"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                 File {filename} is in translation progress, you cannot delete it
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
      </div>
  );
}

    