import { API, graphqlOperation,Auth } from "aws-amplify";
import { PROJECT_DETAILS_LOADING, PROJECT_DETAILS_LOADED, PROJECT_DETAILS_LOAD_FAIL } from "../types/projectDetailTypes";
import { USER_ROLE_IN_PROJECT_LOADING, USER_ROLE_IN_PROJECT_LOADED, USER_ROLE_IN_PROJECT_FAIL } from "../types/projectDetailTypes";
import { getProject3dviewerByNameRelative } from "../../graphql/custom_queries";
import { userRoleQuery,queryDimsPortalsByUsernames } from "../../graphql/DIMSqueries";


export const loadProjectDetails = (projectName: string | undefined) => async (dispatch: Function) => {
    dispatch({type: PROJECT_DETAILS_LOADING})
    try {
        if(projectName!==undefined)
        {
        const response:any = await API.graphql(graphqlOperation(getProject3dviewerByNameRelative, {ProjectName: projectName}))
        const projectInfo = response.data.getProject3dviewerByName.items[0]
        dispatch({type: PROJECT_DETAILS_LOADED, payload: projectInfo,streamingUrl:projectInfo.StreamingURL})
        const res:any = await API.graphql(graphqlOperation(getProject3dviewerByNameRelative, {ProjectName: projectName}))
       }
    }
    catch (error) {
        dispatch({type: PROJECT_DETAILS_LOAD_FAIL, payload: error})
    }
}
export const loadUserRole=(projectName:string | undefined)=> async (dispatch: Function)=>{
    try{
        if(projectName===undefined)
            throw Error("Please enter correct URL")
        dispatch({type:USER_ROLE_IN_PROJECT_LOADING})
        const res=await Auth.currentSession()
        const token=res.getAccessToken().getJwtToken()
        const user = await Auth.currentAuthenticatedUser()
        const userId = await user.attributes.sub
        const endpoint = "https://4dxjxn2slvcxtp5rrdx2kzdjra.appsync-api.eu-north-1.amazonaws.com/graphql";
        const headers = {
            "content-type": "application/json",
            "Authorization": token
        };
        const graphqlQuery = {
            "query": userRoleQuery,
            "variables":{filter:{ProjectName:{eq:projectName.toLowerCase()},UserId:{eq:userId}}}
        };

        const options = {
            "method": "POST",
            "headers": headers,
            "body": JSON.stringify(graphqlQuery)
        };

        let response = await fetch(endpoint, options);
        let data = await response.json();
        let userProjectList:any[]=data.data.listDimsPortals.items
        while(data.data.listDimsPortals.nextToken!==null){
            const nextGraphqlQuery = {
                "query": userRoleQuery,
                "variables":{filter:{ProjectName:{eq:projectName},UserId:{eq:userId}},nextToken:data.data.listDimsPortals.nextToken, limit: 20000000}
            };
            const nextOptions = {
                "method": "POST",
                "headers": headers,
                "body": JSON.stringify(nextGraphqlQuery)
            };
             response = await fetch(endpoint, nextOptions);
             data = await response.json();
             userProjectList=userProjectList.concat(data.data.listDimsPortals.items)
        }
        
        let userRole:string | null=null
        const isUserSuper=await checkSuperAdmin()
        if(isUserSuper==="Super Admin")
        userRole="Writer"
        else
        {
        userProjectList.map((obj:any)=>{
            if(userRole===null && obj.GroupName==="Reader")
                userRole="Reader"
            else if(obj.GroupName==="Writer" || obj.GroupName==="Project Admin" )
                userRole="Writer"
        })
        }
        dispatch({type:USER_ROLE_IN_PROJECT_LOADED,payload:userRole})
    }   
    catch(error){
        dispatch({type: USER_ROLE_IN_PROJECT_FAIL, payload: error})
        
    }
}

const checkSuperAdmin=async()=>{
    try {
        const user = await Auth.currentAuthenticatedUser() 
        const res=await Auth.currentSession()
        const token=res.getAccessToken().getJwtToken()       
        const endpoint = "https://4dxjxn2slvcxtp5rrdx2kzdjra.appsync-api.eu-north-1.amazonaws.com/graphql";
        const headers = {
            "content-type": "application/json",
            "Authorization": token
        };
        const graphqlQuery = {
            "query": queryDimsPortalsByUsernames,
            // "UserId":user.attributes.sub
            "variables":{UserId:user.attributes.sub}
        };

        const options = {
            "method": "POST",
            "headers": headers,
            "body": JSON.stringify(graphqlQuery)
        };

        let response = await fetch(endpoint, options);
        let data = await response.json();
        const resU=data.data.queryDimsPortalsByUsernames.items
        let userRole=""
        if(resU.length===1 && resU[0].pk==="SUPERADMIN")
                userRole="Super Admin"
        else 
                userRole="General User"
        return userRole
        
        
    }
    catch (error) {
        throw error
    }
    return ""
}