import FileUploads from "./fileUploads";
import ProjectDetail from "./projectDetail";
import BucketView from "./BucketView";
import UserRoleReducer from "./userRoleReducer";
import { combineReducers } from "redux"
import translateReducer from "./translateReducer";


const appReducer = combineReducers ({
    FileUploads,
    ProjectDetail,
    translateReducer,
    BucketView,
    UserRoleReducer
});

const rootReducer = (state: any, action: any) => {
    return appReducer(state, action)
}

export default rootReducer;