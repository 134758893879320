import React from 'react';
import {FileListItem} from './FileListItem'
import { connect } from 'react-redux';
interface File{
    name:string,
    size:number
}

interface Props{
    files:File[],
    isUploading?: boolean[],
    isUploaded?: boolean[],
    uploadProgress?: number[] ,
    onDelete: (file: File) => void;
}
 const FileList:React.FC<Props> = ({files,onDelete,...stateProps}) => {
    return (
      <>
          {Array.from(files).map((todo,index)=>(
            stateProps.uploadProgress && stateProps.isUploading && stateProps.isUploaded && <FileListItem key={todo.name}  file={todo}  onDelete={onDelete} isUploaded={stateProps.isUploaded[index]} isUploading={stateProps.isUploading[index]} uploadProgress={stateProps.uploadProgress[index]}/> 
          ))}
         
      </> 
      
  )
         
};
const mapStateToProps = (state: any) => {
    return {
      projectInfo: state.ProjectDetail.projectInfo,
      isProjectLoaded: state.ProjectDetail.isLoaded,
      isUploading: state.FileUploads.isUploading,
      isUploaded: state.FileUploads.isUploaded,
      uploadProgress: state.FileUploads.uploadProgress,
      result: state.FileUploads.result,
      error: state.FileUploads.error,
  
    }
  }
  
  export default connect(mapStateToProps, {})(FileList);