import React, { useState } from "react";
import FloatingMenu from '../FloatingMenu/FloatingMenu';
import { useParams } from 'react-router-dom'
import { connect,useDispatch } from 'react-redux';
import { loadProjectDetails, loadUserRole } from '../../store/actions/projectActions';
import {  Project3dviewer } from '../../API';
import { AccessDenied } from "../organize/AccessDenied";
import PageNotFound from "../organize/PageNotFound";
import { CircularProgress, Grid } from "@mui/material";


interface IViewPortProp {
    token:string,
    isProjectLoading?:boolean,
    projectInfo?: Project3dviewer,
    isProjectLoaded?: boolean,
    streamingUrl:string,
    isuserRoleLoading:boolean,
    isuserRoleLoaded:boolean,
    userRole:string | null
}

const ViewPort = ({token,...stateProps}: IViewPortProp) => {
      let {projectName}=useParams()
      const [frameSrc,setFrameSrc]=useState<string>("")
      const dispatch=useDispatch()

    const fetchProjectInfo = async () => { 

        try {
          dispatch(loadProjectDetails(projectName))
          dispatch(loadUserRole(projectName))
        } catch(error) {
            console.error("Error: ", error)
            projectName="Error";
        }
      }
    const currentEnv = process.env.REACT_APP_3DVIEWER_ENV === undefined ? "dev": process.env.REACT_APP_3DVIEWER_ENV ;
    
    React.useEffect(() => {
        fetchProjectInfo()
      }, [])

    React.useEffect(()=>{
        stateProps.isProjectLoaded && setFrameSrc(stateProps.streamingUrl+"/"+stateProps.projectInfo?.Application +"-"+currentEnv+"/"+currentEnv+"?exeLunchArgs=-token="+token+" -project="+projectName+" -env="+currentEnv)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[stateProps.isProjectLoaded])
    return (
        
        <>
        {stateProps.isProjectLoading  && <Grid sx={{position:'absolute',top:'50%',left:'50%'}}><CircularProgress /></Grid>}
        {stateProps.isProjectLoading===false && stateProps.isProjectLoaded===false  && <PageNotFound/>}
        {stateProps.isProjectLoaded && stateProps.isuserRoleLoading && <Grid sx={{position:'absolute',top:'50%',left:'50%'}}><CircularProgress /></Grid>}
        {stateProps.isProjectLoaded===true && stateProps.isuserRoleLoaded && stateProps.userRole===null &&<AccessDenied />}
        {stateProps.isProjectLoaded===true && stateProps.isuserRoleLoaded && stateProps.userRole!==null && (stateProps.userRole==="Reader" || stateProps.userRole==="Writer") && stateProps.isProjectLoaded &&  <>
        <div className="view-port">
            <iframe id="iframe"
            style={{overflow: "hidden",border:"none"}}
            scrolling="no"
            src={""+frameSrc} width="100%" height="100%" allowFullScreen title={projectName}></iframe>
        </div><FloatingMenu onUploadPage={false}/>
        </>}
        
        </>
    );
}
const mapStateToProps = (state: any) => {
    return {
        projectInfo: state.ProjectDetail.projectInfo,
        userRole:state.UserRoleReducer.userRole,
        isuserRoleLoading:state.UserRoleReducer.isLoading,
        isuserRoleLoaded:state.UserRoleReducer.isLoaded,
        isProjectLoading:state.ProjectDetail.isLoading,
        isProjectLoaded: state.ProjectDetail.isLoaded,
        streamingUrl:state.ProjectDetail.streamingUrl
    }
  }
  export default connect(mapStateToProps, {})(ViewPort);