import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FC } from 'react';

import { Alert, Autocomplete, Checkbox, FormControlLabel, Grid, LinearProgress, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { API, graphqlOperation } from 'aws-amplify';
import { DataSet3dviewer, DataType3dviewer } from '../../API';
import { listDataType3dviewers } from '../../graphql/queries';

const MainDialog = styled(Dialog)(({ theme }: {theme: any}) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

interface IAddDsDialogTitleProps {
    id?: string,
    children: any,
    onClose(): void
}

type DataTypeListItemType = {
  label: string,
  dataType: DataType3dviewer
}

type DataTypeListItemTypes = DataTypeListItemType[]

const AddDsDialogTitle: FC<IAddDsDialogTitleProps> = ({id, children, onClose,}:IAddDsDialogTitleProps) => {

  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface IAddDsDialogProp {
    open: boolean,
    existingDataSets: DataSet3dviewer[],
    onCreate(dataSet: DataSet3dviewer): void,
    onClose(): void,
    result?: any,
    error?: any
}

 const AddDataSetDialog: FC<IAddDsDialogProp> = ({open, existingDataSets, onCreate, onClose, ...stateProps}: IAddDsDialogProp) => {   
    const [initialized, setInitialized] = useState<boolean>(false);    
    const [validated, setValidated] = useState<boolean>(false)

    const [dataSet, setdataSet] = useState<String>("")
    const [shared, setShared] = useState<boolean>(false)    
    const [dataTypeList, setDataTypeList] = useState<DataTypeListItemTypes>([])
    const [dataType, setDataType] = useState<any>("")
    const [openAddDtDialog, setOpenAddDtDialog] = useState<boolean>(false)
    const [dsFieldError, setDsFieldError] = useState<String>("")

    const dispatch = useDispatch()

    const fetchDataTypeList = async () => {
      try{
        const response:any = await API.graphql(graphqlOperation(listDataType3dviewers))
        const list = response.data.listDataType3dviewers.items

        const dataTypeList: DataTypeListItemTypes = [] 
        
        list.forEach((item: any) => dataTypeList.push({label: item.Type, dataType: item}))
        setDataTypeList([...dataTypeList])        
      }
      catch(error) {
        //console.log(error)
      }
    }

    React.useEffect(() => {
      !initialized && fetchDataTypeList()
      setInitialized(true)
      validate()
    }, [
      dataSet, dataType
    ])

    const initialize = () => {
      if(initialized) return
      setInitialized(true)
    }

    const isDsAlreadyExists = (dataSet: String) => 
                                existingDataSets.find(ds =>
                                  ds.DataSet.toLowerCase() === dataSet.toLowerCase())
  
    const validate = () => {
      let isValid = dataSet.length > 0 && dataType

      if(isDsAlreadyExists(dataSet)) {
        isValid = false
        setDsFieldError("DataSet exists alread.!")
      }
      else {
        setDsFieldError("")
      }

      setValidated( isValid)
  }
    
    const onDataSetChange = (e: any) => {
      setdataSet(e.target.value)
    }

    const onDataTypeChange = (e: any, value: any) => {
      setDataType(value)
    }

    const onSharedChanged = (e: any) => {
      //console.log()
      setShared(e.target.checked)
    }

    const onTypeChange = (e: any) => {
      setDataType(e.target.value)
    }

    const onDsCreate =  async (event: any) => {
      //let projectId = projectInfo.Id
      const newDataSet: any = {
        DataSet: "" + dataSet,
        Priority:  null,
        FmeServerFilePath: "",
        SharedData: shared,
        MinTileSize: null,
        FmeServerCached: false,
        Resolution: null,
        Type: dataType.dataType
      }

      onCreate(newDataSet) 
      handleClose()
    }

    const onAddDtButton = () => {
      setOpenAddDtDialog(true)
    }

    const handleClose = () => {
      setShared(false)
      setdataSet("")
      setDataType(null)
      onClose()
      
      // dispatch(uploadDialogClosed())
    }

    return (
    <div>
      <MainDialog
        onClose={handleClose}
        aria-labelledby="upload-dialog-title"
        open={open}
      >
        <AddDsDialogTitle id="customized-dialog-title" onClose={handleClose}>
           Add New Data Set
        </AddDsDialogTitle>
        <DialogContent dividers sx={{minHeight: 350, maxWidth: 450, left: 20, top: 20}}>
            {stateProps.error && <Alert severity="error" sx={{mb:2}}></Alert>}

            <Grid container direction="row" spacing={2}>
              <Grid item xs={10} sx={{mt:2}}>
                <TextField
                  error={dsFieldError.length > 0}
                  helperText={dsFieldError}
                  id="data-source"
                  label="Data Set"
                  variant="outlined"
                  sx={{width: "100%"}}
                  value={dataSet}
                  onChange={onDataSetChange}
                  required
                />
              </Grid>

              {/* <Grid item xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox name="shared" checked={shared} onChange={onSharedChanged}/>
                  }
                  label="Shared"
                />
              </Grid> */}

              <Grid item xs={10} sx={{mt:2}}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={dataTypeList}
                  value={dataType}
                  onChange={onDataTypeChange}
                  renderInput={(params) => <TextField {...params} label="Data Type" />}
                  ListboxProps={
                    {
                      style:{
                          maxHeight: '200px'
                      }
                    }
                  }
                />
              </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={onDsCreate} disabled={!validated}>
                Create
            </Button>
        </DialogActions>
      </MainDialog>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
  }
}

export default connect(mapStateToProps, {})(AddDataSetDialog);