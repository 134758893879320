import {CREATE_BUCKET_VIEW,DELETING_FILE,FILE_DELETED,FILE_DELETITION_FAIL,DELETE_FOLDER,DELETE_FAIL,CREATING_BUCKET_VIEW,BUCKET_VIEW_FAIL} from '../types/BucketViewTypes'
interface dataStructure{
    name:string,
    key:string,
    children:dataStructure[],
  }
export interface IBucketView {
    tree: dataStructure,
    isTree:boolean,
    isTreeLoading:boolean,
    filedelete: string[],
    deletingFile:boolean,
    isFiledeleted:boolean,
    folderdelete: string[]
    error: string
}

const InitialBucketViewState: IBucketView = {
    filedelete: [],
    isTree:false,
    isTreeLoading:false,
    isFiledeleted:false,
    deletingFile:false,
    folderdelete: [],
    tree:{name:"",key:"",children:[]},
    error: ""
}
const BucketView = (state: IBucketView= InitialBucketViewState, action: any) => {
    switch(action.type) {
        case CREATING_BUCKET_VIEW:
            return{
                ...state,
                isTree:false,
                deletingFile:false,
                isTreeLoading:true,

            }
        case CREATE_BUCKET_VIEW:
            return{
                ...state,
                isTree:true,
                deletingFile:false,
                isTreeLoading:false,
                tree:action.payload
            }
        case BUCKET_VIEW_FAIL:
            return{
                ...state,
                isTree:false,
                isTreeLoading:false,
                deletingFile:false,
                error:action.payload
            }
        case DELETING_FILE:
            return{
                ...state,
                deletingFile:true,
                isFiledeleted:false,
                filedelete:[...state.filedelete,action.payload]
            }
        case FILE_DELETED:
            return{
                ...state,
                deletingFile:false,
                isFiledeleted:true,
            }
        case FILE_DELETITION_FAIL:
            return{
                ...state,
                deletingFile:false,
                isFiledeleted:false,
                error:action.payload
            }
        default:
            return state
    }
}
export default BucketView;
