import React from "react";
import {Auth} from "aws-amplify"
import UploadDialog from "../UploadDialog/UploadDialog";
import { IconButton, Tooltip } from "@mui/material";
import { ExitToApp, UploadFile } from "@mui/icons-material";
import { useParams } from 'react-router-dom'
import Organize from '../organize/Organize';
import Link from '@mui/material/Link';

interface Ifloatingmenu{
    onUploadPage:boolean;
}

const FloatingMenu = ({onUploadPage}:Ifloatingmenu) => {
    let {projectName}=useParams()
    
    
    const handleSignout = async (e:any) => {
        await Auth.signOut()
        const currentEnv = process.env.REACT_APP_3DVIEWER_ENV === undefined ? "dev": process.env.REACT_APP_3DVIEWER_ENV ;
        window.location.href = 'https://www.'+currentEnv+'.dimdocks.com';
    }

    return (
        <div className="floating-menu">
            <Tooltip title="Sign out" placement="left-start">
                <IconButton color="error" aria-label="sign out" component="span" onClick={handleSignout}>
                    <ExitToApp/>
                </IconButton>
            </Tooltip>
           {!onUploadPage && <Tooltip title="Upload files" placement="left-start">
                <IconButton color="primary" aria-label="upload file" component="span" >
                    <Link href={"/"+projectName+"/organize"}><UploadFile/></Link>
                </IconButton>
            </Tooltip>}
        </div>
    );
}

export default FloatingMenu;
