/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDataType3dviewer = /* GraphQL */ `
  mutation CreateDataType3dviewer(
    $input: CreateDataType3dviewerInput!
    $condition: ModelDataType3dviewerConditionInput
  ) {
    createDataType3dviewer(input: $input, condition: $condition) {
      id
      Type
      FileTypes
      RequestString
      Workspace
      Description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDataType3dviewer = /* GraphQL */ `
  mutation UpdateDataType3dviewer(
    $input: UpdateDataType3dviewerInput!
    $condition: ModelDataType3dviewerConditionInput
  ) {
    updateDataType3dviewer(input: $input, condition: $condition) {
      id
      Type
      FileTypes
      RequestString
      Workspace
      Description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDataType3dviewer = /* GraphQL */ `
  mutation DeleteDataType3dviewer(
    $input: DeleteDataType3dviewerInput!
    $condition: ModelDataType3dviewerConditionInput
  ) {
    deleteDataType3dviewer(input: $input, condition: $condition) {
      id
      Type
      FileTypes
      RequestString
      Workspace
      Description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDataSet3dviewer = /* GraphQL */ `
  mutation CreateDataSet3dviewer(
    $input: CreateDataSet3dviewerInput!
    $condition: ModelDataSet3dviewerConditionInput
  ) {
    createDataSet3dviewer(input: $input, condition: $condition) {
      id
      DataSet
      Priority
      FmeServerFilePath
      SharedData
      MinTileSize
      FmeServerCached
      Resolution
      Description
      createdAt
      updatedAt
      Type {
        id
        Type
        FileTypes
        RequestString
        Workspace
        Description
        createdAt
        updatedAt
        __typename
      }
      Projects {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateDataSet3dviewer = /* GraphQL */ `
  mutation UpdateDataSet3dviewer(
    $input: UpdateDataSet3dviewerInput!
    $condition: ModelDataSet3dviewerConditionInput
  ) {
    updateDataSet3dviewer(input: $input, condition: $condition) {
      id
      DataSet
      Priority
      FmeServerFilePath
      SharedData
      MinTileSize
      FmeServerCached
      Resolution
      Description
      createdAt
      updatedAt
      Type {
        id
        Type
        FileTypes
        RequestString
        Workspace
        Description
        createdAt
        updatedAt
        __typename
      }
      Projects {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteDataSet3dviewer = /* GraphQL */ `
  mutation DeleteDataSet3dviewer(
    $input: DeleteDataSet3dviewerInput!
    $condition: ModelDataSet3dviewerConditionInput
  ) {
    deleteDataSet3dviewer(input: $input, condition: $condition) {
      id
      DataSet
      Priority
      FmeServerFilePath
      SharedData
      MinTileSize
      FmeServerCached
      Resolution
      Description
      createdAt
      updatedAt
      Type {
        id
        Type
        FileTypes
        RequestString
        Workspace
        Description
        createdAt
        updatedAt
        __typename
      }
      Projects {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createProjectDataSet3dviewer = /* GraphQL */ `
  mutation CreateProjectDataSet3dviewer(
    $input: CreateProjectDataSet3dviewerInput!
    $condition: ModelProjectDataSet3dviewerConditionInput
  ) {
    createProjectDataSet3dviewer(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      dataSet {
        id
        DataSet
        Priority
        FmeServerFilePath
        SharedData
        MinTileSize
        FmeServerCached
        Resolution
        Description
        createdAt
        updatedAt
        __typename
      }
      project {
        id
        ProjectName
        Origin_X
        Bounds
        Origin_Y
        Application
        StreamingURL
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateProjectDataSet3dviewer = /* GraphQL */ `
  mutation UpdateProjectDataSet3dviewer(
    $input: UpdateProjectDataSet3dviewerInput!
    $condition: ModelProjectDataSet3dviewerConditionInput
  ) {
    updateProjectDataSet3dviewer(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      dataSet {
        id
        DataSet
        Priority
        FmeServerFilePath
        SharedData
        MinTileSize
        FmeServerCached
        Resolution
        Description
        createdAt
        updatedAt
        __typename
      }
      project {
        id
        ProjectName
        Origin_X
        Bounds
        Origin_Y
        Application
        StreamingURL
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteProjectDataSet3dviewer = /* GraphQL */ `
  mutation DeleteProjectDataSet3dviewer(
    $input: DeleteProjectDataSet3dviewerInput!
    $condition: ModelProjectDataSet3dviewerConditionInput
  ) {
    deleteProjectDataSet3dviewer(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      dataSet {
        id
        DataSet
        Priority
        FmeServerFilePath
        SharedData
        MinTileSize
        FmeServerCached
        Resolution
        Description
        createdAt
        updatedAt
        __typename
      }
      project {
        id
        ProjectName
        Origin_X
        Bounds
        Origin_Y
        Application
        StreamingURL
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createCoordinateSystem3dviewer = /* GraphQL */ `
  mutation CreateCoordinateSystem3dviewer(
    $input: CreateCoordinateSystem3dviewerInput!
    $condition: ModelCoordinateSystem3dviewerConditionInput
  ) {
    createCoordinateSystem3dviewer(input: $input, condition: $condition) {
      id
      CoordinateSystem
      XMAX
      YMAX
      XMIN
      YMIN
      EPSG
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCoordinateSystem3dviewer = /* GraphQL */ `
  mutation UpdateCoordinateSystem3dviewer(
    $input: UpdateCoordinateSystem3dviewerInput!
    $condition: ModelCoordinateSystem3dviewerConditionInput
  ) {
    updateCoordinateSystem3dviewer(input: $input, condition: $condition) {
      id
      CoordinateSystem
      XMAX
      YMAX
      XMIN
      YMIN
      EPSG
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCoordinateSystem3dviewer = /* GraphQL */ `
  mutation DeleteCoordinateSystem3dviewer(
    $input: DeleteCoordinateSystem3dviewerInput!
    $condition: ModelCoordinateSystem3dviewerConditionInput
  ) {
    deleteCoordinateSystem3dviewer(input: $input, condition: $condition) {
      id
      CoordinateSystem
      XMAX
      YMAX
      XMIN
      YMIN
      EPSG
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProject3dviewer = /* GraphQL */ `
  mutation CreateProject3dviewer(
    $input: CreateProject3dviewerInput!
    $condition: ModelProject3dviewerConditionInput
  ) {
    createProject3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Origin_X
      Bounds
      Origin_Y
      Application
      StreamingURL
      createdAt
      updatedAt
      DataSets {
        nextToken
        __typename
      }
      CoordinateSystem {
        id
        CoordinateSystem
        XMAX
        YMAX
        XMIN
        YMIN
        EPSG
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateProject3dviewer = /* GraphQL */ `
  mutation UpdateProject3dviewer(
    $input: UpdateProject3dviewerInput!
    $condition: ModelProject3dviewerConditionInput
  ) {
    updateProject3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Origin_X
      Bounds
      Origin_Y
      Application
      StreamingURL
      createdAt
      updatedAt
      DataSets {
        nextToken
        __typename
      }
      CoordinateSystem {
        id
        CoordinateSystem
        XMAX
        YMAX
        XMIN
        YMIN
        EPSG
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteProject3dviewer = /* GraphQL */ `
  mutation DeleteProject3dviewer(
    $input: DeleteProject3dviewerInput!
    $condition: ModelProject3dviewerConditionInput
  ) {
    deleteProject3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Origin_X
      Bounds
      Origin_Y
      Application
      StreamingURL
      createdAt
      updatedAt
      DataSets {
        nextToken
        __typename
      }
      CoordinateSystem {
        id
        CoordinateSystem
        XMAX
        YMAX
        XMIN
        YMIN
        EPSG
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createPointsofinterest3dviewer = /* GraphQL */ `
  mutation CreatePointsofinterest3dviewer(
    $input: CreatePointsofinterest3dviewerInput!
    $condition: ModelPointsofinterest3dviewerConditionInput
  ) {
    createPointsofinterest3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Type
      Group
      Name
      Location
      Rotation
      AdditionalData
      createdAt
      updatedAt
      Project {
        id
        ProjectName
        Origin_X
        Bounds
        Origin_Y
        Application
        StreamingURL
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updatePointsofinterest3dviewer = /* GraphQL */ `
  mutation UpdatePointsofinterest3dviewer(
    $input: UpdatePointsofinterest3dviewerInput!
    $condition: ModelPointsofinterest3dviewerConditionInput
  ) {
    updatePointsofinterest3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Type
      Group
      Name
      Location
      Rotation
      AdditionalData
      createdAt
      updatedAt
      Project {
        id
        ProjectName
        Origin_X
        Bounds
        Origin_Y
        Application
        StreamingURL
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deletePointsofinterest3dviewer = /* GraphQL */ `
  mutation DeletePointsofinterest3dviewer(
    $input: DeletePointsofinterest3dviewerInput!
    $condition: ModelPointsofinterest3dviewerConditionInput
  ) {
    deletePointsofinterest3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Type
      Group
      Name
      Location
      Rotation
      AdditionalData
      createdAt
      updatedAt
      Project {
        id
        ProjectName
        Origin_X
        Bounds
        Origin_Y
        Application
        StreamingURL
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createRuleSet3dviewer = /* GraphQL */ `
  mutation CreateRuleSet3dviewer(
    $input: CreateRuleSet3dviewerInput!
    $condition: ModelRuleSet3dviewerConditionInput
  ) {
    createRuleSet3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Name
      Type
      IsDefault
      createdAt
      updatedAt
      Rules {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateRuleSet3dviewer = /* GraphQL */ `
  mutation UpdateRuleSet3dviewer(
    $input: UpdateRuleSet3dviewerInput!
    $condition: ModelRuleSet3dviewerConditionInput
  ) {
    updateRuleSet3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Name
      Type
      IsDefault
      createdAt
      updatedAt
      Rules {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteRuleSet3dviewer = /* GraphQL */ `
  mutation DeleteRuleSet3dviewer(
    $input: DeleteRuleSet3dviewerInput!
    $condition: ModelRuleSet3dviewerConditionInput
  ) {
    deleteRuleSet3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Name
      Type
      IsDefault
      createdAt
      updatedAt
      Rules {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createRule3dviewer = /* GraphQL */ `
  mutation CreateRule3dviewer(
    $input: CreateRule3dviewerInput!
    $condition: ModelRule3dviewerConditionInput
  ) {
    createRule3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Identifiers
      Value
      Type
      Priority
      RuleSetName
      createdAt
      updatedAt
      RuleSet {
        id
        ProjectName
        Name
        Type
        IsDefault
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateRule3dviewer = /* GraphQL */ `
  mutation UpdateRule3dviewer(
    $input: UpdateRule3dviewerInput!
    $condition: ModelRule3dviewerConditionInput
  ) {
    updateRule3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Identifiers
      Value
      Type
      Priority
      RuleSetName
      createdAt
      updatedAt
      RuleSet {
        id
        ProjectName
        Name
        Type
        IsDefault
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteRule3dviewer = /* GraphQL */ `
  mutation DeleteRule3dviewer(
    $input: DeleteRule3dviewerInput!
    $condition: ModelRule3dviewerConditionInput
  ) {
    deleteRule3dviewer(input: $input, condition: $condition) {
      id
      ProjectName
      Identifiers
      Value
      Type
      Priority
      RuleSetName
      createdAt
      updatedAt
      RuleSet {
        id
        ProjectName
        Name
        Type
        IsDefault
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createJobStatus3dviewer = /* GraphQL */ `
  mutation CreateJobStatus3dviewer(
    $input: CreateJobStatus3dviewerInput!
    $condition: ModelJobStatus3dviewerConditionInput
  ) {
    createJobStatus3dviewer(input: $input, condition: $condition) {
      id
      FMEJobId
      ErrorMessage
      WarningMessage
      FIleNameKey
      Status
      UserName
      ProjectName
      TransformScript
      StartTime
      UpdateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateJobStatus3dviewer = /* GraphQL */ `
  mutation UpdateJobStatus3dviewer(
    $input: UpdateJobStatus3dviewerInput!
    $condition: ModelJobStatus3dviewerConditionInput
  ) {
    updateJobStatus3dviewer(input: $input, condition: $condition) {
      id
      FMEJobId
      ErrorMessage
      WarningMessage
      FIleNameKey
      Status
      UserName
      ProjectName
      TransformScript
      StartTime
      UpdateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteJobStatus3dviewer = /* GraphQL */ `
  mutation DeleteJobStatus3dviewer(
    $input: DeleteJobStatus3dviewerInput!
    $condition: ModelJobStatus3dviewerConditionInput
  ) {
    deleteJobStatus3dviewer(input: $input, condition: $condition) {
      id
      FMEJobId
      ErrorMessage
      WarningMessage
      FIleNameKey
      Status
      UserName
      ProjectName
      TransformScript
      StartTime
      UpdateTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
