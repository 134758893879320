import { Box, CircularProgress, Container, Grid } from '@mui/material';
import { height } from '@mui/system';
import ReplayIcon from '@mui/icons-material/Replay';
import DoneIcon from '@mui/icons-material/Done';
import { Project3dviewer } from 'API';
import { DataGrid, GridRowsProp,GridFilterModel, GridColDef,GridColumns,GridRowHeightParams,GridColumnVisibilityModel,useGridApiRef,useGridApiContext, GridRenderCellParams,GridToolbar,GridRenderEditCellParams } from '@mui/x-data-grid';
import * as React from 'react';
import  DataSet  from './DataSet';
import { connect, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {Error} from './Error'
import TreeRecursive from '../BucketView/TreeRecursive';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useParams } from 'react-router-dom'
import FloatingMenu from '../FloatingMenu/FloatingMenu';
import { BreadCrumb } from './BreadCrumb';
import './FileList.css'
import logo from './wsp.svg'
import { loadProjectDetails, loadUserRole } from 'store/actions/projectActions';
import { AccessDenied } from './AccessDenied';
import PageNotFound from './PageNotFound';
import { emptyKeyArray, storingTranslateKeys, translation } from 'store/actions/translateFileActions';
interface dataStructure{
    name:string,
    key:string,
    children:dataStructure[],
  }
interface ITranslateFilesGrid{
    projectInfo?: Project3dviewer,
    isProjectLoading?:boolean,
    isProjectLoaded?: boolean,
    isUploading?: boolean[],
    jobsData:any[],
    isUploaded?: boolean[],
    isTreeLoading:boolean,
    uploadProgress?: number[],
    result?: any[],
    error?: string[],
    keyArray:string[],
    isTree?:boolean,
    tree?:dataStructure,
    isFiledeleted?:boolean,
    deletingFile?:boolean,
    fileNames:string[],
    isuserRoleLoading:boolean,
    isuserRoleLoaded:boolean,
    userRole:string | null
}
export const TranslateFilesGrid=({...stateProps}:ITranslateFilesGrid)=>{
  const [pageSize, setPageSize] = React.useState<number>(5);
  const dispatch=useDispatch()
  const retryTranslation=(params:any)=>{
    dispatch(emptyKeyArray())
    dispatch(storingTranslateKeys(params.row.FileNameKey.slice(20)))//removing 3dviewer-bucket-dev from the file path as it will include in dispatch function
    dispatch(translation(stateProps.keyArray.join(','),stateProps.projectInfo!.ProjectName))
  }
  const renderActionsCell=(params:any)=>{ 
    if(params.row.Status==="Running" || params.row.Status==="Started")
      {return <Grid><CircularProgress size={'1.5rem'}/></Grid>}
    else if(params.row.Status==="Done")
      {return <Grid><DoneIcon color='success'/></Grid>}
    else if(params.row.Status==="Failed")
      {
        return(
        <Grid  sx={{cursor:'pointer'}} onClick={()=>{retryTranslation(params)}}>
          <ReplayIcon color="error"/>
        </Grid>
        )
      }


  }
  const renderFileName=(params:any)=>{
    const temp:string[]=params.row.FileNameKey.split('/')
    return(
      <Grid container sx={{pl:2}}>{temp[temp.length-1]}</Grid>
    )
  }
  function renderCreatedDate(params:any){ //Return tag for Created and Modified Date Cells
    const date=new Date(params.value)
    return <Grid  sx={{ml:3,mr:1}}>{date.toLocaleString()}</Grid>
  
  }
  const columns:GridColDef[]=[
    {field:'FileName',headerName:'File Name',headerClassName: 'table-header',width:200,headerAlign: 'center',flex:1,filterable:false, align:'center',hideable:false,disableColumnMenu:true,renderCell:renderFileName},
    {field:'FileNameKey',headerName:'File Path',headerClassName: 'table-header',width:200,headerAlign: 'center',flex:1,filterable:false, align:'center',hideable:false,disableColumnMenu:true},
    {field:'UserName',headerName:'Translated By',headerClassName: 'table-header',width:250,headerAlign: 'center',align:'left',hideable:false,disableColumnMenu:true},
    {field:'StartTime',headerName:'Start Time',headerClassName: 'table-header',width:220,headerAlign: 'center',align:'left',sortable:false,hideable:false,disableColumnMenu:true,renderCell:renderCreatedDate},
    {field:'Status',headerName:'Status',headerClassName: 'table-header',width:100,headerAlign: 'center',sortable:false,hideable:false,disableColumnMenu:true},
    {field:'ErrorMessage',headerName:'Error Message',headerClassName: 'table-header',width:200,headerAlign: 'center',filterable:false, align:'center',hideable:false,disableColumnMenu:true},
    {field:'Actions',headerName:'Actions',headerClassName: 'table-header',width:100,headerAlign: 'center',align:'center',sortable:false,hideable:false,disableColumnMenu:true,renderCell:renderActionsCell}
  ]
    return(
        <Box className='abc'  sx={{display:'flex',width:'100%', height: '70vh',mb:5,'& .table-header':{backgroundColor:'red',color:'white',fontSize:'16px'} }}>
          <DataGrid rows={stateProps.jobsData} 
            columns={columns}
            autoHeight={true}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20,25]}
            pageSize={pageSize}
            sortingOrder={['desc', 'asc']}
            components={{ Toolbar: GridToolbar}}
            pagination
            sx={{
              '& .MuiDataGrid-toolbarContainer button':{
                color:'red'
              },
              '& .MuiDataGrid-main':{
                overflowY:'auto'
              },
              '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell':{
                whiteSpace:'initial',
                maxHeight:'100% !important'
              },
              '& .MuiDataGrid-cell': {
                lineHeight: 'unset !important',
                maxHeight: 'none !important',
                whiteSpace: 'initial',
                minHeight:'100%',
                wordBreak: 'break-word',
                textAlign:'left'
                    },
                    '& .MuiDataGrid-row': {
                      // lineHeight: 'unset !important',
                        maxHeight:'100% !important'
                      // maxHeight: 'initial !important',

                    },
                    '& .MuiDataGrid-virtualScroller': {
                      height: '100% !important',
                      overflow:'auto !important',
                    }
            }}
          />
        </Box>
    )
}
const mapStateToProps = (state: any) => {
    return {
      projectInfo: state.ProjectDetail.projectInfo,
      isProjectLoading:state.ProjectDetail.isLoading,
      isProjectLoaded: state.ProjectDetail.isLoaded,
      isUploading: state.FileUploads.isUploading,
      isUploaded: state.FileUploads.isUploaded,
      uploadProgress: state.FileUploads.uploadProgress,
      result: state.FileUploads.result,
      error: state.FileUploads.error,
      isTree:state.BucketView.isTree,
      isTreeLoading:state.BucketView.isTreeLoading,
      fileNames:state.FileUploads.fileNames,
      tree:state.BucketView.tree,
      isFiledeleted:state.BucketView.isFiledeleted,
      keyArray:state.translateReducer.keyArray,
      jobsData:state.translateReducer.jobsData,
      deletingFile:state.BucketView.deletingFile,
      userRole:state.UserRoleReducer.userRole,
      isuserRoleLoading:state.UserRoleReducer.isLoading,
      isuserRoleLoaded:state.UserRoleReducer.isLoaded
    }
  }
  
  export default connect(mapStateToProps, {})(TranslateFilesGrid);