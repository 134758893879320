
import React from 'react'
import { useParams } from 'react-router-dom'
import FloatingMenu from '../FloatingMenu/FloatingMenu';


const PageNotFound = () => {
    let {projectName}=useParams()
    const url="/"+projectName
    return (
        <>
        <div className="container">
        <h2>404</h2>
        <h3>Oops, nothing here...</h3>
        <p>Please Check the URL and Enter Correct Project Name</p>
        {/* <p>Otherwise, <a href={url}>Click here</a> to redirect to homepage.</p> */}
    </div>
    <FloatingMenu onUploadPage={true}/>

    </>
    )
}

export default PageNotFound