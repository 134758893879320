import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
interface AlertOptions {
    open: boolean,
    fileTypesArray:String[],
    onClose(): void
}

export const AlertDialog: React.FC<AlertOptions> = ({open,fileTypesArray,onClose}: AlertOptions) => {   

      
    const handleClose = () => {
        onClose()
    };
    return(
      <div>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"You have selected wrong files"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Please select the files with {fileTypesArray.join(',')} extensions only
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
      </div>
  );
}

    