import * as React from 'react';
import List from "@mui/material/List";
import {ErrorListItem} from './ErrorListItem'

interface Props{
    error?:string[]
}
export const Error:React.FC<Props> = ({error}) => {
return(
    <List>
  {error && error.map((err)=>{
        return(<ErrorListItem error={err}/>)           

    })}
    </List>
)
    
};