import { connect,useDispatch } from 'react-redux';
import {Auth} from "aws-amplify"
import { useState,useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { deleteFile } from '../../store/actions/ViewS3BucketActions';
import {Folder} from './Folder'
import {File} from './File'
import { FileDeleteAlertDialog } from './FileDeleteAlertDialog';
import { deleteJob, storingTranslateKeys } from 'store/actions/translateFileActions';
import { ImpossibleDeleteAlert } from './ImpossibleDeleteAlert';

interface dataStructure{
    name:string,
    key:string,
    children:dataStructure[],
  }
interface IBucketDialogProp {
    structure:dataStructure,
    isFiledeleted:boolean,
    deletingFile:boolean,
    fileNames:string[],
    keyArray:string[],
    jobsData:any[]

}
const TreeRecursive: React.FC<IBucketDialogProp> = ({structure,keyArray,isFiledeleted,deletingFile,fileNames,jobsData}: IBucketDialogProp) => {
    const [jwt, setJwt] = useState<string>("")
    const [jobId,setJobId]=useState<string>("")
    const [open,setOpen]=useState<boolean>(false)
    const [impossibleDelete,setImpossibleDelete]=useState<boolean>(false)
    const dispatch = useDispatch()
    Auth.currentSession().then((res:any)=>{
        let accessToken = res.getAccessToken()
        setJwt(accessToken.getJwtToken())
        })
    const commitDelete=(filekey:String)=>{
        dispatch(deleteFile(filekey,jwt))
        dispatch(deleteJob(jobId))
        setOpen(false)
    }
    const closeImpossibleDeleteDialog=()=>{
        setImpossibleDelete(false)
    }
    const openAlertDialog=()=>{
        const bucketPath="3dviewer-bucket-dev/"+structure.key
        for(var i=0;i<jobsData.length;i++){
            if(structure.children.length===0 && bucketPath===jobsData[i].FileNameKey && (jobsData[i].Status==='Running' || jobsData[i].Status==='Started')){
                setImpossibleDelete(true)
                return;
            }
            if(structure.children.length===0 && bucketPath===jobsData[i].FileNameKey && jobsData[i].Status!=='Running' && jobsData[i].Status!=='Started'){
                setJobId(jobsData[i].id)
            }
                
        }
        setOpen(true)
    }
    const onClose=()=>{
        setOpen(false)
    }
    useEffect(()=>{
        if(structure.children.length===0 &&  fileNames.indexOf(structure.name)!==-1 && keyArray.indexOf(structure.key)===-1){
            dispatch(storingTranslateKeys(structure.key))
        }
    },[structure])
    if(structure.children.length===0){
        return(
            <>
            <File name={structure.name} fileKey={structure.key} key={structure.key} commitDelete={openAlertDialog} deletingFile={deletingFile} isFiledeleted={isFiledeleted}/>
            <FileDeleteAlertDialog open={open} commitDelete={commitDelete} fileKey={structure.key} onClose={onClose}/>
            <ImpossibleDeleteAlert open={impossibleDelete} onClose={closeImpossibleDeleteDialog} filename={structure.name} />
            </>
            )
    }
    
       
    return (
            <Folder key={structure.name} name={structure.name} >  
                {structure.children.map((elem)=>{ return <TreeRecursive keyArray={keyArray} jobsData={jobsData} key={elem.name} fileNames={fileNames} structure={elem} deletingFile={deletingFile} isFiledeleted={isFiledeleted}/>})}
             </Folder>            
             
        
    )
        
}


const mapStateToProps = (state: any) => {
    return {
      projectInfo: state.ProjectDetail.projectInfo,
      isProjectLoaded: state.ProjectDetail.isLoaded,
      isUploading: state.FileUploads.isUploading,
      isUploaded: state.FileUploads.isUploaded,
      uploadProgress: state.FileUploads.uploadProgress,
      result: state.FileUploads.result,
      error: state.FileUploads.error,
  
    }
  }
  
  export default connect(mapStateToProps, {})(TreeRecursive);