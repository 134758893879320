import { Box, Container, Grid } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { useState,useEffect,useLayoutEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './Folder.css'

import {
    AiOutlineFolder,
    AiOutlineFolderOpen,
  } from "react-icons/ai";
  interface IBucketDialogProp {
    name:string,
    children:any
}
interface foldername{
    isOpen:boolean,
    handlefolderopen:()=>void,
    name:string

}
const FolderName:React.FC<foldername>=({isOpen,handlefolderopen,name}:foldername)=>{
    return(
    <div className='folder' onClick={handlefolderopen}>
        {isOpen?<KeyboardArrowDownIcon fontSize="small"/>:<ChevronRightIcon fontSize="small"/>}
        {isOpen ? <AiOutlineFolderOpen /> : <AiOutlineFolder />}
            <span>{name}</span></div>
    )
    }
 export const Folder: React.FC<IBucketDialogProp> = ({name,children}: IBucketDialogProp) => {
    
    const [childs, setChilds] = useState<any>();
    const [isOpen,setISOpen]=useState<boolean>(true)
    function handlefolderopen(){
        isOpen?setISOpen(false):setISOpen(true)
    }
    useEffect(() => {
        setChilds([children]);
      }, [children]);
    return (
        <div className="folderDiv">
        {/* <section className='verticalLine'> */}
        <FolderName isOpen={isOpen} handlefolderopen={handlefolderopen} name={name}/>
        {isOpen && <div className="tree__folder--collapsible" >
          {childs}
        </div>}
        {/* </section> */}
        </div>
        )


}